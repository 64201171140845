import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAiyWHOxQ6TH9fqbGiX8z0JtP-On8c5yjs",
  authDomain: "controle-financeiro-pess-6782a.firebaseapp.com",
  projectId: "controle-financeiro-pess-6782a",
  storageBucket: "controle-financeiro-pess-6782a.appspot.com",
  messagingSenderId: "687888953717",
  appId: "1:687888953717:web:f69b8269fc435f58dea33c",
  measurementId: "G-RBLMT6EE5S"
};

// init firebase
initializeApp(firebaseConfig);

// init firestore service
const db = getFirestore();
const auth = getAuth();

// export firestore
export { db, auth };
