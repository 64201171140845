<template>
  <div class="container-fluid">
    <nav-bar></nav-bar>
    <router-view></router-view>
  </div>
</template>

<script>
import { auth } from '../../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';

import NavBar from './NavBar.vue'
export default {
  name: "DefaultLayout",
  components: {
    NavBar
  },
  computed:{
    usuario() {
      return this.$store.state.usuario;
    },
    dados() {
      return this.$store.state.anos;
    }
  },
  methods: {
    initVariaveis() {
      if(Object.keys(this.usuario).length < 1){
        onAuthStateChanged(auth, (user) => {
          if (user) {
            //this.userFirebase = user
            //Dispatch dos dados na store
            this.$store.dispatch('carregaDadosFirebase', user.uid);
            this.$store.dispatch('carregaAnosFirebase', user.uid);
          }
        });
      }
    },
  },
  mounted() {
    this.initVariaveis();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        this.$router.push({ name: 'Entrar' })
      }
    });
  }
}
</script>

<style>

</style>
