import Vue from 'vue'
import VueRouter from 'vue-router'
import Entrar from '../views/Entrar.vue'
import Dashboard from '../views/Dashboard.vue';
import Operacao from '../views/Operacao.vue'
import DefaultLayout from '../views/Layout/DefaultLayout.vue'
import Relatorio from '../views/Relatorio.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: DefaultLayout,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'operacao',
        name: 'Operacao',
        component: Operacao,
      },
      {
        path: 'relatorio',
        name: 'Relatorio',
        component: Relatorio
      }
    ]
  },
  {
    path: '/entrar',
    name: 'Entrar',
    component: Entrar
  }
]

const router = new VueRouter({
  routes
})

export default router
