<template>
  <div class="container-fluid pt-3">
    <div class="row pt-5">
      <div class="col-lg-12 col-sm-12">
        <h1 class="display-1">Controle Financeiro Pessoal<br> <small class="text-muted">Versão 1.0.6 de 15/03/2023</small></h1>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-sm-12">
        <div class="row justify-content-between">
          <div class="col-lg-2 col-sm-1">
            <button type="button" class="btn btn-primary btn-sm my-1" @click="mesAtual = mesAtual-1"><i class="fas fa-caret-left"></i> {{anoSelecionado.meses[mesAtual-1].mes}}</button>
          </div>
          <div class="col-lg-2 col-sm-1">
            <button type="button" class="btn btn-primary btn-sm my-1" @click="mesAtual = mesAtual+1">{{anoSelecionado.meses[mesAtual+1].mes}} <i class="fas fa-caret-right"></i></button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-sm-12">
        <div class="table-responsive" v-if="anoAtual != ''">
          <table class="table table-sm" style="width:400px">
            <thead>
              <tr>
                <th scope="col" colspan="3">{{anoSelecionado.meses[mesAtual].mes}}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-danger" v-for="(despesa, index) in anoSelecionado.meses[mesAtual].despesas" :key="index">
                <td class="fs-6" style="width:60%">{{despesa.nome}}</td>
                <td class="fs-6" style="width:10%">
                  <i class="icone-desabilitado fas fa-check-circle" v-if="despesa.status == 'Pago'"></i>
                  <i class="text-warning fas fa-clock" v-else></i>
                </td>
                <td class="fs-6 fw-semibold">
                  <i class="text-danger far fa-arrow-alt-circle-up" v-if="despesa.valorPago > despesa.valor"></i>
                  <i class="text-success far fa-arrow-alt-circle-down" v-else-if="despesa.valorPago < despesa.valor"></i>
                  {{despesa.status == 'Pago' ? parseFloat(despesa.valorPago).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) : parseFloat(despesa.valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</td>
              </tr>
              <tr class="table-success" v-for="(entradas, index) in anoSelecionado.meses[mesAtual].entradas" :key="`e${index}`">
                <td class="fs-6">{{entradas.nome}}</td>
                <td>
                  <i class="icone-desabilitado fas fa-check-double" v-if="entradas.confirmado"></i>
                  <i class="text-warning fas fa-hourglass-half" v-else></i>
                </td>
                <td class="fs-6 fw-semibold">{{parseFloat(entradas.valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</td>
              </tr>
              <tr class="table-light">
                <td class="fs-6 fw-bold">Total de despesas (previsto)</td>
                <td></td>
                <td class="fs-6 fw-bold"><p class="text-danger mb-0">{{calcTotalDespesas(anoSelecionado.meses[mesAtual].despesas).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</p></td>
              </tr>
              <tr class="table-light">
                <td class="fs-6 fw-bold">Total de entradas</td>
                <td></td>
                <td class="fs-6 fw-bold"><span class="text-success">{{calcTotalEntradas(anoSelecionado.meses[mesAtual].entradas).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</span></td>
              </tr>
              <tr class="table-light">
                <td class="fs-6 fw-bold">Restante Previsto</td>
                <td></td>
                <td class="fs-6 fw-bold"><span :class="calcRestantePrevisto(anoSelecionado.meses[mesAtual].despesas, anoSelecionado.meses[mesAtual].entradas) > -1 ? 'text-primary' : 'text-danger'">R$ {{calcRestantePrevisto(anoSelecionado.meses[mesAtual].despesas, anoSelecionado.meses[mesAtual].entradas).toLocaleString('pt-BR')}}</span></td>
              </tr>
              <tr class="table-light">
                <td class="fs-6 fw-bold">Total de despesas (pagas)</td>
                <td>
                  <i class="text-danger far fa-arrow-alt-circle-up" v-if="totalPago(anoSelecionado.meses[mesAtual].despesas) > calcTotalDespesas(anoSelecionado.meses[mesAtual].despesas)"></i>
                  <i class="text-success far fa-arrow-alt-circle-down" v-else-if="totalPago(anoSelecionado.meses[mesAtual].despesas) < calcTotalDespesas(anoSelecionado.meses[mesAtual].despesas)"></i>
                  <i class="text-primary fas fa-equals" v-else-if="totalPago(anoSelecionado.meses[mesAtual].despesas) == calcTotalDespesas(anoSelecionado.meses[mesAtual].despesas)"></i>
                </td>
                <td class="fs-6 fw-bold"><p class="text-danger mb-0">
                  {{totalPago(anoSelecionado.meses[mesAtual].despesas).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</p></td>
              </tr>
              <tr class="table-light">
                <td class="fs-6 fw-bold">Restante</td>
                <td>
                  <i class="text-success far fa-arrow-alt-circle-up" v-if="calcRestante(anoSelecionado.meses[mesAtual].despesas, anoSelecionado.meses[mesAtual].entradas) > calcRestantePrevisto(anoSelecionado.meses[mesAtual].despesas, anoSelecionado.meses[mesAtual].entradas)"></i>
                        <i class="text-warning far fa-arrow-alt-circle-down" v-else-if="calcRestante(anoSelecionado.meses[mesAtual].despesas, anoSelecionado.meses[mesAtual].entradas) < calcRestantePrevisto(anoSelecionado.meses[mesAtual].despesas, anoSelecionado.meses[mesAtual].entradas)"></i>
                        <i class="text-primary fas fa-equals" v-else-if="calcRestante(anoSelecionado.meses[mesAtual].despesas, anoSelecionado.meses[mesAtual].entradas) == calcRestantePrevisto(anoSelecionado.meses[mesAtual].despesas, anoSelecionado.meses[mesAtual].entradas)"></i>
                </td>
                <td class="fs-6 fw-bold"><span :class="calcRestante(anoSelecionado.meses[mesAtual].despesas, anoSelecionado.meses[mesAtual].entradas) > -1 ? 'text-primary' : 'text-danger'">R$ {{calcRestante(anoSelecionado.meses[mesAtual].despesas, anoSelecionado.meses[mesAtual].entradas).toLocaleString('pt-BR')}}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  data() {
    return {
      anoAtual: "",
      mesAtual: 0
    }
  },
  computed: {
    dados: {
      get() {
        return this.$store.state.anos;
      },
      set(newData) {
        this.$store.dispatch('defineNovoStateAnos', newData);
      }
    },
    anoSelecionado: {
      get() {
        //const dados = this.dados.find(i => i.ano === this.anoAtual.toString());
        const dados = this.$store.getters.getAnoSelecionado(this.anoAtual.toString());
        //console.log('dados no computed -> ', dados)
        return dados
      },
      set(newValue) {
        this.anoAtual = newValue
      },
    },
  },
  methods: {
    initValues() {
      const currentDate = new Date();
      this.anoAtual = currentDate.getFullYear();
      this.mesAtual = currentDate.getMonth();
    },
    calcTotalDespesas(despesas) {
      if(despesas.length > 0) {
        var count = 0
        for(var d = 0; d < despesas.length; d++) {
          count += parseFloat(despesas[d].valor)
        }
        return count
      } else {
        return 0.00
      }
    },
    calcTotalEntradas(entradas) {
      if(entradas.length > 0) {
        var count = 0
        for(var d = 0; d < entradas.length; d++) {
          count += parseFloat(entradas[d].valor)
        }
        return count
      } else {
        return 0.00
      }
    },
    calcRestantePrevisto(despesas, entradas) {
      var despesasTotal = this.calcTotalDespesas(despesas);
      var entradasTotal = this.calcTotalEntradas(entradas);
      return entradasTotal - despesasTotal;
    },
    calcRestante(despesas, entradas) {
      var despesasTotal = this.totalPago(despesas);
      var entradasTotal = this.calcTotalEntradas(entradas);
      return entradasTotal - despesasTotal;
    },
    totalPago(despesas) {
      if(despesas.length > 0) {
        var count = 0
        for(var d = 0; d < despesas.length; d++) {
          count += parseFloat(despesas[d].valorPago)
        }
        return count
      } else {
        return 0.00
      }
    }
  },
  mounted() {
    //this.initValues();
    const currentDate = new Date();
    console.log('currentDate -> ', currentDate)
    this.anoAtual = currentDate.getFullYear();
    this.mesAtual = currentDate.getMonth();
  }
}
</script>

<style>

</style>
