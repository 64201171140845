<template>
  <div class="container pt-5">
    <div class="row py-3">
      <div class="col-12 text-center">
        <h1 class="display-1">Controle Financeiro Pessoal</h1>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <!-- Card de Login -->
        <div class="card mx-auto" style="width: 25rem;" v-if="flLogin">
          <div class="card-header">
            Login
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <label for="emailLogin" class="form-label">E-mail</label>
                <input class="form-control" type="email" id="emailLogin" v-model="email">
              </div>
            </div>
            <div class="row my-2">
              <div class="col">
                <label for="senha" class="form-label">Senha</label>
                <input class="form-control" type="password" id="senha" v-model="senha">
              </div>
            </div>
            <div class="row my-2">
              <div class="col text-center">
                <button type="submit" class="btn btn-primary" @click="realizaLogin">Entrar</button>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col">
                <a href="#/entrar" @click="alteraTela('registro')">Registrar-se</a>
              </div>
              <div class="col-auto">
                <a href="#/entrar" class="ms-auto" @click="alteraTela('recuperar')">Esqueci minha senha</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Card de Registro -->
        <div class="card mx-auto" style="width: 25rem;" v-if="flRegistro">
          <div class="card-header">
            Registro
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <label for="nome" class="form-label">Nome</label>
                <input class="form-control" type="email" id="nome" v-model="nome">
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="emailLogin" class="form-label">E-mail</label>
                <input class="form-control" type="email" id="emailLogin" v-model="email">
              </div>
            </div>
            <div class="row my-2">
              <div class="col">
                <label for="senha" class="form-label">Senha</label>
                <input class="form-control" type="password" id="senha" v-model="senha">
              </div>
            </div>
            <div class="row my-2">
              <div class="col text-center">
                <button type="submit" class="btn btn-primary" @click="registraNovoUsuario">Registrar</button>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col">
                <a href="#/entrar" @click="alteraTela('login')">Voltar para o Login</a>
              </div>
              <div class="col-auto">
                <a href="#/entrar" class="ms-auto" @click="alteraTela('recuperar')">Esqueci minha senha</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Card de Recuperação de senha -->
        <div class="card mx-auto" style="width: 25rem;" v-if="flRecuperarSenha">
          <div class="card-header">
            Recuperar Senha
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <label for="emailLogin" class="form-label">E-mail</label>
                <input class="form-control" type="email" id="emailLogin" v-model="email">
              </div>
            </div>
            <div class="row my-2">
              <div class="col text-center">
                <button type="submit" class="btn btn-primary">Solicitar Nova Senha</button>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col">
                <a href="#/entrar" @click="alteraTela('registro')">Registrar-se</a>
              </div>
              <div class="col-auto">
                <a href="#/entrar" @click="alteraTela('login')">Voltar para o Login</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, db } from '../firebase/config';
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  onAuthStateChanged
} from 'firebase/auth';
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import moment from "moment";

export default {
  name: "Entrar",
  data() {
    return {
      flLogin: true,
      flRegistro: false,
      flRecuperarSenha: false,
      nome: "",
      email: "",
      senha: ""
    }
  },
  methods: {
    verificaLogin() {
      if (sessionStorage.getItem('access_token')) {
        var dateNow = new Date();
        var expireTokeon = new Date(sessionStorage.getItem('session_end_datetime'));
        if(expireTokeon > dateNow) {
          this.$router.push({ name: 'lista-de-anuncios' }).catch(() => {});
          return true;
        } else {
          sessionStorage.clear();
          this.$router.push({ name: 'Home' }).catch(() => {});
          return false;
        }
      } else {
        sessionStorage.clear();
        this.$router.push({ name: 'Home' }).catch(() => {});
        return false;
      }
    },
    async registraNovoUsuario() {
      await createUserWithEmailAndPassword(auth, this.email, this.senha)
        .then((res) => {
          console.log('res createUserWithEmailAndPassword -> ', res)
          updateProfile(auth.currentUser, {
            displayName: this.nome
          }).then(() => {
            //console.log('nome atualizado ->>>')
            this.criarFirestoreRegistro(res.user);
            this.criarFirestoreRegistroAno(res.user);
          }).catch( err => {
            console.log('erro na atualização do nome -> ', err.message)
            return
          })
          alert('Registro realizado com sucesso.');
          this.email = "";
          this.senha = "";
          this.nome = "";
        })
        .catch(error => {
          alert(error.message);
          this.email = "";
          this.senha = "";
          this.nome = "";
          return
        });
    },
    async criarFirestoreRegistro(usuario) {
      const dateNow = new Date();
      var dados = {
        created_at: moment(dateNow).format("YYYY-MM-DD HH:mm"),
        displayName: usuario.displayName,
        email: usuario.email
      }
      try {
        //const res = await addDoc(collection(db, 'work4data-anuncios'), dados)
        const res = await setDoc(doc(db, "dados-usuarios", usuario.uid), dados);
        //const res = await addDoc(collection(db, 'work4data-anuncios'), dados)
        console.log('res criacao de area de dados -> ', res)
        return
      } catch(err) {
        console.log('err -> ', err);
        return
      }
    },
    async criarFirestoreRegistroAno(usuario) {
      console.log('usuario -> ', usuario)
      const dateNow = new Date();
      var anoAtual = dateNow.getFullYear();
      var initialData = {
        ano: anoAtual.toString(),
        meses: [
          {
            mes: "Janeiro",
            entradas: [],
            despesas: []
          },
          {
            mes: "Fevereiro",
            entradas: [],
            despesas: []
          },
          {
            mes: "Março",
            entradas: [],
            despesas: []
          },
          {
            mes: "Abril",
            entradas: [],
            despesas: []
          },
          {
            mes: "Maio",
            entradas: [],
            despesas: []
          },
          {
            mes: "Junho",
            entradas: [],
            despesas: []
          },
          {
            mes: "Julho",
            entradas: [],
            despesas: []
          },
          {
            mes: "Agosto",
            entradas: [],
            despesas: []
          },
          {
            mes: "Setembro",
            entradas: [],
            despesas: []
          },
          {
            mes: "Outubro",
            entradas: [],
            despesas: []
          },
          {
            mes: "Novembro",
            entradas: [],
            despesas: []
          },
          {
            mes: "Dezembro",
            entradas: [],
            despesas: []
          }
        ],
      }
      try {
        //const refCollectio = doc(collection(db, `dados-usuarios/${usuario.uid}/${anoAtual}`));
        //const res1 = await setDoc(refCollectio, initialData);
        const res1 = await addDoc(collection(db, `dados-usuarios/${usuario.uid}/anos`), initialData)
        console.log('res1 criacao de area de dados -> ', res1);
        return
      } catch(err) {
        console.log('err -> ', err);
        return
      }
    },
    async recuperarSenha() {
      if (this.email !== '') {
        await sendPasswordResetEmail(auth, this.email)
          .then(() => {
            alert('Uma nova senha foi enviada para o seu e-mail!');
            this.fl_recuperarSenha = false;
            this.email = "";
            return
          }).catch(err => {
            console.log('Erro na solicitação de nova senha -> ', err.message)
            console.log('Error code -> ', err.code)
            console.log('Error message -> ', err.message)
            alert('Ops! Houve algum problema e não foi possível solicitar uma nova senha.');
            this.fl_recuperarSenha = false;
            this.email = "";
            return
          })
      } else {
        alert('Você precisa informar o seu e-mail cadastrado.');
        this.email = "";
      }
    },
    async realizaLogin() {
      await signInWithEmailAndPassword(auth, this.email, this.senha)
        .then(() => {
          this.fl_login = false;
          this.$router.push({ name: 'Dashboard' }).catch(err => {
            console.log('erro no router -> ', err)
          });
        }).catch( err => {
          this.fl_login = false;
          console.log('erro login -> ', err.message)
          alert('Falha no login')
        })
    },
    verificarUsuarioLogadoFirebase() {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          //const uid = user.uid;
          console.log('user firebase -> ', user)
          // ...
        } else {
          // User is signed out
          // ...
        }
      });
    },
    alteraTela(tela) {
      if (tela === 'login') {
        this.flLogin = true;
        this.flRegistro = false;
        this.flRecuperarSenha = false;
      } else if (tela === 'registro') {
        this.flLogin = false;
        this.flRegistro = true;
        this.flRecuperarSenha = false;
      } else if (tela === 'recuperar') {
        this.flLogin = false;
        this.flRegistro = false;
        this.flRecuperarSenha = true;
      }
    }
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$router.push({ name: 'Dashboard' }).catch(err => {
          console.log('erro no router -> ', err)
        });
      }/*  else {
        console.log('falha na validação do login no Firestore')
        return
      } */
    });
  },
  created() {
    this.clienteId = process.env.VUE_APP_APP_ID;
    this.redirectUri = process.env.VUE_APP_REDIRECT_URI;
    //this.state = Math.floor(Math.random() * 100);
    this.state = Date.now();
  }
}
</script>

<style>

</style>
