import Vue from 'vue'
import Vuex from 'vuex'
import { db } from "../firebase/config";
import { doc, getDoc, getDocs, collection, addDoc } from "firebase/firestore";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario: {},
    anos: []
  },
  getters: {
    getAnoSelecionado: (state) => (anoAtual) => {
      //console.log('store state.anos -> ', state.anos)
      return state.anos.find(i => i.ano === anoAtual);
    }
  },
  mutations: {
    resetState(state) {
      state.usuario = {};
      state.anos = [];
    },
    loadAnos(state, payload) {
      state.anos = payload;
    },
    addNovoAnoState(state, payload) {
      state.anos.push(payload);
    },
    saveUsuario(state, payload) {
      state.usuario = payload;
    },
    addDespesaState(state, payload) {
      const indiceAno = state.anos.findIndex(i => i.ano === payload.ano);
      //console.log('mutation indiceAno -> ', indiceAno)
      //console.log('mutation payload -> ', payload)
      //const indiceMes = state.anos[indiceAno].meses.findIndex(m => m.meses === payload.mes);
      state.anos[indiceAno].meses[payload.mes].despesas.push(payload.despesa);
    },
    addEntradaState(state, payload) {
      const indiceAno = state.anos.findIndex(i => i.ano === payload.ano);
      state.anos[indiceAno].meses[payload.mes].entradas.push(payload.entrada);
    },
    atualizaDespesaState(state, payload) {
      const indiceAno = state.anos.findIndex(i => i.ano === payload.ano);
      const indiceDespesa = state.anos[indiceAno].meses[payload.mes].despesas.findIndex(d => d.id === payload.id);
      state.anos[indiceAno].meses[payload.mes].despesas[indiceDespesa].dataPagto = payload.dataPagto;
      state.anos[indiceAno].meses[payload.mes].despesas[indiceDespesa].nome = payload.nome;
      state.anos[indiceAno].meses[payload.mes].despesas[indiceDespesa].status = payload.status;
      state.anos[indiceAno].meses[payload.mes].despesas[indiceDespesa].valorPago = payload.valorPago;
      state.anos[indiceAno].meses[payload.mes].despesas[indiceDespesa].valor = payload.valor;
      state.anos[indiceAno].meses[payload.mes].despesas[indiceDespesa].data = payload.data;
      state.anos[indiceAno].meses[payload.mes].despesas[indiceDespesa].nota = payload.nota;
    },
    removeDespesaState(state, payload) {
      const indiceAno = state.anos.findIndex(i => i.ano === payload.ano);
      const indiceDespesa = state.anos[indiceAno].meses[payload.mes].despesas.findIndex(d => d.id === payload.id);
      state.anos[indiceAno].meses[payload.mes].despesas.splice(indiceDespesa, 1);
    },
    atualizaEntradaState(state, payload) {
      const indiceAno = state.anos.findIndex(i => i.ano === payload.ano);
      const indiceEntrada = state.anos[indiceAno].meses[payload.mes].entradas.findIndex(d => d.id === payload.id);
      state.anos[indiceAno].meses[payload.mes].entradas[indiceEntrada].nome = payload.nome;
      state.anos[indiceAno].meses[payload.mes].entradas[indiceEntrada].confirmado = payload.confirmado;
      state.anos[indiceAno].meses[payload.mes].entradas[indiceEntrada].valor = payload.valor;
      state.anos[indiceAno].meses[payload.mes].entradas[indiceEntrada].data = payload.data;
      state.anos[indiceAno].meses[payload.mes].entradas[indiceEntrada].nota = payload.nota;
    },
    removeEntradaState(state, payload) {
      const indiceAno = state.anos.findIndex(i => i.ano === payload.ano);
      const indiceEntrada = state.anos[indiceAno].meses[payload.mes].entradas.findIndex(d => d.id === payload.id);
      state.anos[indiceAno].meses[payload.mes].entradas.splice(indiceEntrada, 1);
    },
    /*
    addItemConfiguracoes(state, payload) {
      Vue.set(state.configuracoes, payload.key, payload.value)
      //state.configuracoes.push(payload)
    },
    addProdutos(state, payload) {
      payload.forEach(element => {
        Vue.set(element.json_produto, 'id_produto', element.id_produto)
        state.produtos.push(element.json_produto)
      });
    }
    */
  },
  actions: {
    resetaStateGeral({commit}) {
      commit('resetState');
    },
    async carregaDadosFirebase({commit}, uid) {
      const resConfig = doc(db, "dados-usuarios", uid);
      //console.log("instanciando .doc -> ", resConfig);
      await getDoc(resConfig).then((snapshot) => {
        //console.log('snapshot -> ', snapshot.data());
        let docs = { ...snapshot.data(), id: snapshot.id };
        //console.log('docs -> ', docs);
        //this.dadosFirebase = docs;
        commit('saveUsuario', docs)
      });
    },
    async carregaAnosFirebase({commit}, uid) {
      const colRef = "dados-usuarios/" + uid + "/anos";
      const resAnos = collection(db, colRef);
      await getDocs(resAnos).then((snapshot) => {
        let docs = [];
        snapshot.docs.forEach((doc) => {
          //docs.push({ ...doc.data(), id: doc.id }); //Essa linha alterava o id do anuncio pelo ID de gravação do Firestore
          //console.log('doc -> ', doc)
          docs.push({ ...doc.data(), id_firestore: doc.id });
        });
        commit('loadAnos', docs)
      });
    },
    async addNovoAno({commit, state}, novoAno) {
      try {
        //const refCollectio = doc(collection(db, `dados-usuarios/${usuario.uid}/${anoAtual}`));
        //const res1 = await setDoc(refCollectio, initialData);
        //console.log('uid -> ', uid)
        const res1 = await addDoc(collection(db, `dados-usuarios/${state.usuario.id}/anos`), novoAno)
        console.log('res1 criacao de area de dados -id> ', res1);
        Vue.set(novoAno, 'id_firestore', res1.id)
        commit('addNovoAnoState', novoAno);
        return
      } catch(err) {
        console.log('err -> ', err);
        return
      }
    },
    addDespesaRecorrente({commit}, data) {
      for(var i = data.mesAtual; i < (data.mesAtual + data.meses); i++) {
        //this.anoSelecionado.meses[i].despesas.push(data);
        const payload = {
          despesa: data.despesa,
          ano: data.ano.toString(),
          mes: i
        }
        commit('addDespesaState', payload)
      }
    },
    addDespesaSimples({commit}, data) {
      const payload = {
        despesa: data.despesa,
        ano: data.ano.toString(),
        mes: data.mesAtual
      }
      commit('addDespesaState', payload)
    },
    addEntradaSimples({commit}, data) {
      const payload = {
        entrada: data.entrada,
        ano: data.ano.toString(),
        mes: data.mesAtual
      }
      commit('addEntradaState', payload)
    },
    defineNovoStateAnos({commit}, data) {
      commit('loadAnos', data)
    },
    atualizaDespesa({commit}, data) {
      commit('atualizaDespesaState', data);
    },
    removeDespesa({commit}, data) {
      commit('removeDespesaState', data);
    },
    removeDespesaRecorrente({commit}, data) {
      for(var i = 0; i < data.length; i++) {
        commit('removeDespesaState', data[i]);
      }
    },
    atualizaEntrada({commit}, data) {
      commit('atualizaEntradaState', data)
    },
    removeEntrada({commit}, data) {
      commit('removeEntradaState', data);
    },
  },
  modules: {
  }
})
