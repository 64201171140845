<template>
  <div class="container pt-3">
    <div class="row pt-5">
      <div class="col-12">
        <h1>Ano: {{anoAtual}}</h1>
      </div>
    </div>
    <div class="row">
      <template v-for="(anos, index) in dados">
      <div class="col-lg-1 col-sm-2" :key="index" v-if="anos.ano != anoAtual">
        <h6><a href="#" @click="alteraAno(anos.ano)">{{anos.ano}}</a></h6>
      </div>
      </template>
      <div class="col-1" v-if="!flAddNovoAno"><i class="icone-novo-ano fas fa-plus-circle" @click="flAddNovoAno = !flAddNovoAno"></i></div>
    </div>
    <transition name="fade">
    <div class="row my-2 align-self-center" v-if="flAddNovoAno">
      <label for="novoAno" class="form-label col-sm-1 col-form-label">Novo ano:</label>
      <div class="col-sm-2 align-self-center">
        <input class="form-control" type="number" id="novoAno" placeholder="2022" v-model="novoAno">
      </div>
      <div class="col-sm-1 align-self-center">
        <button type="button" class="btn btn-primary btn-sm" @click="adcionaNovoAno">Adicionar</button>
      </div>
    </div>
    </transition>
    <!-- Select de meses -->
    <div class="row">
      <div class="col-sm-4">
        <label for="selectMesesDoAno" class="form-label">Mês</label>
        <select class="form-select" id="selectMesesDoAno" v-model="mesSelecionado">
          <template v-for="(mes, index) in anoSelecionado.meses">
          <option :value="index" :key="index">{{mes.mes}}</option>
          </template>
        </select>
      </div>
    </div>
    <!-- Botoes -->
    <div class="row my-2">
      <div class="col-lg-2 col-sm-2">
        <button type="button" class="btn btn-danger" @click="flNovaDespesa = !flNovaDespesa">Nova Despesa</button>
      </div>
      <div class="col-lg-2 col-sm-2">
        <button type="button" class="btn btn-success" @click="flNovaEntrada = !flNovaEntrada">Nova Entrada</button>
      </div>
      <!--
      <div class="col-2">
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">modal</button>
      </div>
      <div class="col-2">
        <button type="button" class="btn btn-primary" @click="carregaDadosFirebase">Firebase</button>
      </div>
      <div class="col-2">
        <button type="button" class="btn btn-light" @click="verificaDados">Verifica Dados</button>
      </div>
       -->
    </div>
      <!-- Despesas -->
    <div class="row" v-if="flNovaDespesa">
      <div class="col-lg-3 col-sm-4">
        <label for="novaDespesaNome" class="form-label">Nova despesa</label>
        <input class="form-control" type="text" id="novaDespesaNome" v-model="novaDespesaNome">
      </div>
      <div class="col-lg-2 col-sm-4">
        <label for="novaDespesaValor" class="form-label">Valor</label>
        <input class="form-control" type="number" id="novaDespesaValor" v-model="novaDespesaValor">
      </div>
      <div class="col-lg-1 col-sm-4">
        <label for="diaVencimento" class="form-label">Vencimento</label>
        <select class="form-select" id="diaVencimento" v-model="novaDespesaDiaVencimento">
          <template v-for="(dia, index) in arrDias">
          <option :value="dia" :key="index">{{dia}}</option>
          </template>
        </select>
      </div>
      <div class="col-lg-3 col-sm-4 align-self-center">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="novaDespesaRecorrente" id="cobrancaRecorrenteFlag">
          <label class="form-check-label" for="cobrancaRecorrenteFlag">
            Despesa recorrente?
          </label>
        </div>
      </div>
      <div class="col-lg-1 col-sm-4" v-if="novaDespesaRecorrente">
        <label for="mesesRecorrentesInput" class="form-label">Meses</label>
        <input class="form-control" type="number" id="mesesRecorrentesInput" v-model="novaDespesaMesesRecorrentes" min="1" :max="12 - mesSelecionado">
      </div>
      <div class="col-lg-1 col-sm-4 align-self-end">
        <button type="button" class="btn btn-primary" @click="addDespesa">Add</button>
      </div>
    </div>
      <!-- Entradas -->
    <div class="row" v-if="flNovaEntrada">
      <div class="col-lg-6 col-sm-12">
        <div class="row">
          <div class="col-lg-4 col-sm-4">
            <label for="novaEntradaNome" class="form-label">Nova entrada</label>
            <input class="form-control" type="text" id="novaEntradaNome" v-model="novaEntradaNome">
          </div>
          <div class="col-lg-3 col-sm-4">
            <label for="novaEntradaValor" class="form-label">Valor</label>
            <input class="form-control" type="text" id="novaEntradaValor" v-model="novaEntradaValor">
          </div>
          <div class="col-lg-4 col-sm-4">
            <label for="novaEntradaData" class="form-label">Data</label>
            <input class="form-control" type="date" id="novaEntradaData" v-model="novaEntradaData">
          </div>
          <div class="col-lg-1 col-sm-4 align-self-end">
            <button type="button" class="btn btn-success" @click="addEntrada">Add</button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Valor</th>
            <th scope="col">Data</th>
            <th scope="col">Situação</th>
            <th scope="col">Valor pago</th>
            <th scope="col">Data Pagto</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-danger" v-for="(despesa, index) in anoSelecionado.meses[mesSelecionado].despesas" :key="index">
            <td>{{despesa.nome}}</td>
            <td>{{parseFloat(despesa.valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</td>
            <td>{{ajustaDataDespesa(despesa.data, mesSelecionado, anoAtual)}}</td>
            <td><span class="text-success fw-bold" v-if="despesa.status == 'Pago'">{{despesa.status}}</span></td>
            <td>
              <i class="text-danger far fa-arrow-alt-circle-up" v-if="despesa.valorPago > despesa.valor"></i>
              <i class="text-success far fa-arrow-alt-circle-down" v-else-if="despesa.valorPago < despesa.valor"></i>
              {{parseFloat(despesa.valorPago).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}
            </td>
            <td>{{despesa.dataPagto | formatDateTime}}</td>
            <td class="text-end">
              <button type="button" class="btn btn-success btn-sm mr-1" data-bs-toggle="modal" data-bs-target="#confirmaPagtoModal" @click="confirmarPagamento(despesa)" v-if="despesa.status != 'Pago'">
                <i class="fas fa-thumbs-up"></i>
              </button>
              <!-- <button type="button" class="btn btn-light btn-sm mx-1">
                <i class="fas fa-file-alt"></i>
              </button> -->
              <button type="button" class="btn btn-warning btn-sm mx-1" data-bs-toggle="modal" data-bs-target="#EditarDespesaModal" @click="alterarDespesa(despesa)"><i class="fas fa-pen"></i></button>
              <button type="button" class="btn btn-danger btn-sm ml-1" data-bs-toggle="modal" data-bs-target="#excluirDespesaModal" @click="excluirDespesa(despesa)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
          <tr class="table-success" v-for="(entrada, index) in anoSelecionado.meses[mesSelecionado].entradas" :key="`e${index}`">
            <td>{{entrada.nome}}</td>
            <td>{{parseFloat(entrada.valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</td>
            <td>{{entrada.data | formatDateTime}}</td>
            <td><span :class="entrada.confirmado ? 'text-success fw-bold' : 'text-warning fw-bold'">{{entrada.confirmado ? 'Confirmado' : 'Pendente'}}</span></td>
            <td></td>
            <td></td>
            <td class="text-end">
              <!-- <button type="button" class="btn btn-light btn-sm mr-1"><i class="fas fa-file-alt"></i></button> -->
              <button type="button" class="btn btn-warning btn-sm mx-1" data-bs-toggle="modal" data-bs-target="#EditarEntradaModal" @click="editarEntrada(entrada)"><i class="fas fa-pen"></i></button>
              <button type="button" class="btn btn-danger btn-sm ml-1" data-bs-toggle="modal" data-bs-target="#excluirEntradaModal" @click="editarEntrada(entrada)"><i class="fas fa-trash-alt"></i></button>
            </td>
          </tr>
          <tr class="table-light">
            <td><strong>Total de despesas (previsto)</strong></td>
            <td colspan="6"><p class="text-danger mb-0"><strong>{{totalDespesas.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</strong></p></td>
          </tr>
          <tr class="table-light">
            <td><strong>Total de entradas</strong></td>
            <td colspan="6"><span class="text-success"><strong>{{totalEntradas.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</strong></span></td>
          </tr>
          <tr class="table-light">
            <td><strong>Restante Previsto</strong></td>
            <td colspan="6"><span :class="(totalEntradas - totalDespesas) > -1 ? 'text-primary' : 'text-danger'"><strong>R$ {{(totalEntradas - totalDespesas).toLocaleString('pt-BR')}}</strong></span></td>
          </tr>
          <tr class="table-light">
            <td><strong>Total de despesas (pagas)</strong></td>
            <td colspan="6"><p class="text-danger mb-0">
              <i class="text-danger far fa-arrow-alt-circle-up" v-if="totalPago > totalDespesas"></i>
              <i class="text-success far fa-arrow-alt-circle-down" v-else-if="totalPago < totalDespesas"></i>
              <i class="text-primary fas fa-equals" v-else-if="totalPago == totalDespesas"></i>
              <strong> {{totalPago.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</strong></p></td>
          </tr>
          <tr class="table-light">
            <td><strong>Restante</strong></td>
            <td colspan="6">
              <i class="text-success far fa-arrow-alt-circle-up" v-if="(totalEntradas - totalPago) > (totalEntradas - totalDespesas)"></i>
              <i class="text-warning far fa-arrow-alt-circle-down" v-else-if="(totalEntradas - totalPago) < (totalEntradas - totalDespesas)"></i>
              <i class="text-primary fas fa-equals" v-else-if="(totalEntradas - totalPago) == (totalEntradas - totalDespesas)"></i>
              <span :class="(totalEntradas - totalPago) > -1 ? 'text-success' : 'text-danger'"><strong> R$ {{(totalEntradas - totalPago).toLocaleString('pt-BR')}}</strong></span>
              </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal Pagamento -->
    <div class="modal fade" id="confirmaPagtoModal" tabindex="-1" aria-labelledby="confirmaPagtoModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="confirmaPagtoModalLabel">Confirmar pagamento</h1>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <h5>{{despesaSelecionada.nome}}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="valorPagoConfirmacao" class="form-label">Valor pago</label>
                <input class="form-control" type="number" id="valorPagoConfirmacao" v-model="despesaSelecionada.valorPago">
              </div>
              <div class="col">
                <label for="dataPagamento" class="form-label">Data</label>
                <input class="form-control" type="date" id="dataPagamento" v-model="despesaSelecionada.dataPagto">
              </div>
            </div>
            <!-- {{despesaSelecionada}} -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cancelaConfirmarPagto">Fechar</button>
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="alteraDespesaPaga">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Edição de Despesa -->
    <div class="modal fade" id="EditarDespesaModal" tabindex="-1" aria-labelledby="EditarDespesaModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="EditarDespesaModalLabel">Editar Despesa</h1>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <h5>{{despesaSelecionada.nome}} - Mês: {{arrMeses[mesSelecionado]}}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="nomeDespesaAlteracao" class="form-label">Nome</label>
                <input class="form-control" type="text" id="nomeDespesaAlteracao" v-model="despesaSelecionada.nome">
              </div>
              <div class="col">
                <label for="valorDespesaAlteracao" class="form-label">Valor</label>
                <input class="form-control" type="number" id="valorDespesaAlteracao" v-model="despesaSelecionada.valor">
              </div>
              <div class="col">
                <label for="diaVencimentoAlteracao" class="form-label">Dia do Vencimento</label>
                <select class="form-select" id="diaVencimentoAlteracao" v-model="despesaSelecionada.data">
                  <template v-for="(dia, index) in arrDias">
                  <option :value="dia" :key="index">{{dia}}</option>
                  </template>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="statusAlteracao" class="form-label">Status</label>
                <select class="form-select" id="statusAlteracao" v-model="despesaSelecionada.status">
                  <option value="Pago">Pago</option>
                  <option value="Pendente">Pendente</option>
                </select>
              </div>
              <div class="col">
                <label for="valorPagoAlteracao" class="form-label">Valor Pago</label>
                <input class="form-control" type="number" id="valorPagoAlteracao" v-model="despesaSelecionada.valorPago">
              </div>
              <div class="col">
                <label for="dataPagtoAlteração" class="form-label">Data do Pagto</label>
                <input class="form-control" type="date" id="dataPagtoAlteração" v-model="despesaSelecionada.dataPagto">
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="notaDespesaAlteracao" class="form-label">Nota</label>
                <textarea class="form-control" id="notaDespesaAlteracao" rows="3" v-model="despesaSelecionada.nota"></textarea>
              </div>
            </div>
            <!-- {{despesaSelecionada}} -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cancelaConfirmarPagto">Fechar</button>
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="alteraDespesaState">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Exclusão de Despesa -->
    <div class="modal fade" id="excluirDespesaModal" tabindex="-1" aria-labelledby="excluirDespesaModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="excluirDespesaModalLabel">Excluir Despesa</h1>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <h5>{{despesaSelecionada.nome}}</h5>
              </div>
            </div>
            <template v-if="despesasEncontradas.length > 1">
              <div class="row">
                <div class="col">
                  Essa despesa é <strong>recorrente</strong>.
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="radioExcluiDespesa" id="radioExcluiDespesa1" value="unica" v-model="exclusaoSelecionada">
                    <label class="form-check-label" for="radioExcluiDespesa1">
                      Você deseja excluir apenas essa?
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="radioExcluiDespesa" id="radioExcluiDespesa2" value="serie" v-model="exclusaoSelecionada">
                    <label class="form-check-label" for="radioExcluiDespesa2">
                      Ou deseja excluir toda a recorrencia?
                    </label>
                  </div>
                </div>
                <div class="col" v-if="exclusaoSelecionada === 'unica'">
                  {{arrMeses[mesSelecionado]}}/{{anoAtual}} - {{parseFloat(despesaSelecionada.valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}} - <span :class="despesaSelecionada.status === 'Pago' ? 'text-success' : 'text-danger'">{{despesaSelecionada.status}}</span><br>
                  <small v-if="despesaSelecionada.status === 'Pago'">Pago em: {{despesaSelecionada.dataPagto | formatDateTime}} - Valor pago: {{parseFloat(despesaSelecionada.valorPago).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</small>
                </div>
                <div class="col" v-else-if="exclusaoSelecionada === 'serie'">
                  <ul>
                    <li v-for="(despesa, index) in despesasEncontradas" :key="index">
                      {{arrMeses[despesa.mes]}}/{{anoAtual}} - {{parseFloat(despesa.valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}} - <span :class="despesa.status === 'Pago' ? 'text-success' : 'text-danger'">{{despesa.status}}</span><br>
                      <small v-if="despesa.status === 'Pago'">Pago em: {{despesa.dataPagto | formatDateTime}} - Valor pago: {{parseFloat(despesa.valorPago).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</small>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-else>
            <div class="row">
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="radioExcluiDespesa" id="radioExcluiDespesa1" value="unica" v-model="exclusaoSelecionada">
                  <label class="form-check-label" for="radioExcluiDespesa1">
                    Tem certeza que deseja excluir essa despesa?
                  </label>
                </div>
              </div>
              <div class="col">
                {{arrMeses[mesSelecionado]}}/{{anoAtual}} - {{parseFloat(despesaSelecionada.valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}} - <span :class="despesaSelecionada.status === 'Pago' ? 'text-success' : 'text-danger'">{{despesaSelecionada.status}}</span><br>
                <small v-if="despesaSelecionada.status === 'Pago'">Pago em: {{despesaSelecionada.dataPagto | formatDateTime}} - Valor pago: {{parseFloat(despesaSelecionada.valorPago).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</small>
              </div>
            </div>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cancelaExclusao">Fechar</button>
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" :disabled="exclusaoSelecionada === ''" @click="confirmaExclusao">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Edição de Entrada -->
    <div class="modal fade" id="EditarEntradaModal" tabindex="-1" aria-labelledby="EditarEntradaModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="EditarEntradaModalLabel">Editar Entrada</h1>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <h5>{{entradaSelecionada.nome}} - Mês: {{arrMeses[mesSelecionado]}}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="nomeEntradaAlteracao" class="form-label">Nome</label>
                <input class="form-control" type="text" id="nomeEntradaAlteracao" v-model="entradaSelecionada.nome">
              </div>
              <div class="col">
                <label for="valorEntradaAlteracao" class="form-label">Valor</label>
                <input class="form-control" type="number" id="valorEntradaAlteracao" v-model="entradaSelecionada.valor">
              </div>
              <div class="col">
                <label for="dataEntradaAlteracao" class="form-label">Data da Entrada</label>
                <input class="form-control" type="date" id="valorEntradaAlteracao" v-model="entradaSelecionada.data">
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="confirmadoAlteracao" class="form-label">Status</label>
                <select class="form-select" id="confirmadoAlteracao" v-model="entradaSelecionada.confirmado">
                  <template v-for="(status, index) in arrStatusEntrada">
                    <option :value="status.value" :key="index">{{status.label}}</option>
                  </template>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="notaEntradaAlteracao" class="form-label">Nota</label>
                <textarea class="form-control" id="notaEntradaAlteracao" rows="3" v-model="entradaSelecionada.nota"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cancelaAlteracaoEntrada">Fechar</button>
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="confirmarAlteracaoEntrada">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Exclusão de Entrada -->
    <div class="modal fade" id="excluirEntradaModal" tabindex="-1" aria-labelledby="excluirEntradaModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="excluirEntradaModalLabel">Excluir Despesa</h1>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <h5>{{entradaSelecionada.nome}}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="radioExcluiDespesa" id="radioExcluiDespesa1" value="unica" v-model="exclusaoSelecionada">
                  <label class="form-check-label" for="radioExcluiDespesa1">
                    Tem certeza que deseja excluir essa despesa?
                  </label>
                </div>
              </div>
              <div class="col">
                {{arrMeses[mesSelecionado]}}/{{anoAtual}} - {{parseFloat(entradaSelecionada.valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}} - <span :class="entradaSelecionada.confirmado ? 'text-success' : 'text-danger'">{{entradaSelecionada.confirmado ? 'Confirmado' : 'Pendente'}}</span><br>
                <small v-if="entradaSelecionada.confirmado === 'Pago'">Pago em: {{entradaSelecionada.data | formatDateTime}} - Valor: {{parseFloat(entradaSelecionada.valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</small>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cancelaExclusao">Fechar</button>
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" :disabled="exclusaoSelecionada === ''" @click="confirmarExclucaoEntrada">Confirmar</button>
          </div>
        </div>
      </div>
    </div>

    <!-- RawData de despesas e entradas do mês selecionado -->
    <!-- <div class="row">
      <div class="col-6">
        <template v-for="(despesa, index) in anoSelecionado.meses[mesSelecionado].despesas">
          <div :key="index">
            {{despesa}}
          </div>
        </template>
      </div>
      <div class="col-6">
        <template v-for="(entradas, index) in anoSelecionado.meses[mesSelecionado].entradas">
          <div :key="index">
            {{entradas}}
          </div>
        </template>
      </div>
    </div>
    despesaSelecionada -> <pre>{{despesaSelecionada}}</pre>
    despesasEncontradas -> <pre>{{despesasEncontradas}}</pre>
    exclusaoSelecionada -> <pre>{{exclusaoSelecionada}}</pre>
    userFirebase -> <pre>{{userFirebase}}</pre>
    anoSelecionado -><pre>{{anoSelecionado}}</pre>
    dados -><pre>{{dados}}</pre> -->
  </div>
</template>

<script>
import { auth, db } from '../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import moment from "moment";
import { format } from 'date-fns';

export default {
  name: "Operacao",
  components: {},
  data() {
    return {
      userFirebase: {
        uid: "",
        email: "",
        displayName: ""
      },
      idCollumm: 1,
      novoAno: "",
      anoAtual: "",
      flAddNovoAno: false,
      //anoSelecionado: {},
      mesSelecionado: 0,
      flNovaDespesa: false,
      flNovaEntrada: false,
      despesaSelecionada: {},
      despesasEncontradas: [],
      exclusaoSelecionada: "",
      entradaSelecionada: {},
      /* Variaveis de Nova despesa */
      novaDespesaNome: "",
      novaDespesaValor: "",
      novaDespesaDiaVencimento: "",
      novaDespesaRecorrente: false,
      novaDespesaMesesRecorrentes: 0,
      /* Variaveis de Nova entrada */
      novaEntradaNome: "",
      novaEntradaData: "",
      novaEntradaValor: "",
      /* Variaveis de Totais */
      //totalDespesas: 0,
      //totalEntradas: 0,
      dados1: [],
      dadosOriginal: [
        {
          ano: '2023',
          meses: [
            {
              mes: "Janeiro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Fevereiro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Março",
              entradas: [],
              despesas: []
            },
            {
              mes: "Abril",
              entradas: [],
              despesas: []
            },
            {
              mes: "Maio",
              entradas: [],
              despesas: []
            },
            {
              mes: "Junho",
              entradas: [],
              despesas: []
            },
            {
              mes: "Julho",
              entradas: [],
              despesas: []
            },
            {
              mes: "Agosto",
              entradas: [],
              despesas: []
            },
            {
              mes: "Setembro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Outubro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Novembro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Dezembro",
              entradas: [],
              despesas: []
            }
          ],
        },
        {
          ano: '2022',
          meses: [
            {
              mes: "Janeiro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Fevereiro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Março",
              entradas: [],
              despesas: []
            },
            {
              mes: "Abril",
              entradas: [],
              despesas: []
            },
            {
              mes: "Maio",
              entradas: [],
              despesas: []
            },
            {
              mes: "Junho",
              entradas: [],
              despesas: []
            },
            {
              mes: "Julho",
              entradas: [],
              despesas: []
            },
            {
              mes: "Agosto",
              entradas: [],
              despesas: []
            },
            {
              mes: "Setembro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Outubro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Novembro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Dezembro",
              entradas: [],
              despesas: []
            }
          ],
        }
      ],
      arrMeses: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      arrDias: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
      arrStatusEntrada: [
        { value: true, label: 'Confirmado' },
        { value: false, label: 'Pendente' }
      ]
    }
  },
  computed: {
    dados: {
      get() {
        return this.$store.state.anos;
      },
      set(newData) {
        this.$store.dispatch('defineNovoStateAnos', newData);
      }
    },
    anoSelecionado: {
      get() {
        //const dados = this.dados.find(i => i.ano === this.anoAtual.toString());
        const dados = this.$store.getters.getAnoSelecionado(this.anoAtual.toString());
        //console.log('dados no computed -> ', dados)
        return dados
      },
      set(newValue) {
        this.anoAtual = newValue
      },
    },
    usuario() {
      return this.$store.state.usuario;
    },
    totalDespesas() {
      if(this.anoSelecionado.meses[this.mesSelecionado].despesas.length > 0) {
        var count = 0
        for(var d = 0; d < this.anoSelecionado.meses[this.mesSelecionado].despesas.length; d++) {
          count += parseFloat(this.anoSelecionado.meses[this.mesSelecionado].despesas[d].valor)
        }
        return count
      } else {
        return 0.00
      }
    },
    totalEntradas() {
      if(this.anoSelecionado.meses[this.mesSelecionado].entradas.length > 0) {
        var count = 0
        for(var d = 0; d < this.anoSelecionado.meses[this.mesSelecionado].entradas.length; d++) {
          count += parseFloat(this.anoSelecionado.meses[this.mesSelecionado].entradas[d].valor)
        }
        return count
      } else {
        return 0.00
      }
    },
    totalPago() {
      if(this.anoSelecionado.meses[this.mesSelecionado].despesas.length > 0) {
        var count = 0
        for(var d = 0; d < this.anoSelecionado.meses[this.mesSelecionado].despesas.length; d++) {
          count += parseFloat(this.anoSelecionado.meses[this.mesSelecionado].despesas[d].valorPago)
        }
        return count
      } else {
        return 0.00
      }
    }
  },
  methods: {
    initValues() {
      const currentDate = new Date();
      this.anoAtual = currentDate.getFullYear();
      this.mesSelecionado = currentDate.getMonth();
    },
    ajustaDataDespesa(dia, mes, ano) {
      var dataAjustada = format(new Date(ano, mes, dia), 'dd/MM/yyyy');
      return dataAjustada;
    },
    async carregaDadosFirebase() {
      const colRefNotif = "dados-usuarios/" + this.userFirebase.uid + "/anos";
      const resConfig = collection(db, colRefNotif);
      let docs = [];
      await getDocs(resConfig).then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        this.dados = docs;
      });
    },
    addDespesa() {
      const data = {
        id: this.geradorIds(),
        nome: this.novaDespesaNome,
        valor: this.novaDespesaValor,
        data: this.novaDespesaDiaVencimento,
        status: "Pendente",
        valorPago: 0,
        dataPagto: "",
        nota: ""
      }
      const payload = {
        despesa: data,
        ano: this.anoAtual.toString(),
        mesAtual: this.mesSelecionado,
        meses: parseInt(this.novaDespesaMesesRecorrentes)
      }
      if(this.novaDespesaRecorrente) {
        this.$store.dispatch('addDespesaRecorrente', payload)
      } else {
        this.$store.dispatch('addDespesaSimples', payload)
      }
      setTimeout(() => {
        this.updateFirebaseAnoAtualizado();
        this.novaDespesaNome = "";
        this.novaDespesaValor = "";
        this.novaDespesaDiaVencimento = "";
        this.novaDespesaRecorrente = false;
        this.novaDespesaMesesRecorrentes = 0;
      }, 500);
    },
    addEntrada() {
      const data = {
        id: this.geradorIds(),
        nome: this.novaEntradaNome,
        valor: this.novaEntradaValor,
        data: this.novaEntradaData,
        confirmado: false,
        nota: ""
      }
      const payload = {
        entrada: data,
        ano: this.anoAtual.toString(),
        mesAtual: this.mesSelecionado
      }
      this.$store.dispatch('addEntradaSimples', payload)
      this.novaEntradaNome = "";
      this.novaEntradaData = "";
      this.novaEntradaValor = "";
      setTimeout(() => {
        this.updateFirebaseAnoAtualizado();
      }, 500);
    },
    async updateFirebaseAnoAtualizado() {
      try {
        await updateDoc(doc(db, "dados-usuarios/" + this.userFirebase.uid + "/anos", this.anoSelecionado.id_firestore), this.anoSelecionado);
      } catch(err) {
        console.log('err grava conta ML -> ', err)
      }
      return
    },
    geradorIds() {
      let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
        }
      //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
      var guid = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();

      return guid;
    },
    confirmarPagamento(despesa) {
      //this.despesaSelecionada = structuredClone(despesa);
      this.despesaSelecionada = structuredClone(despesa);
      this.despesaSelecionada.valorPago = this.despesaSelecionada.valor;
      this.despesaSelecionada.dataPagto = format(Date.now(), 'yyyy-MM-dd');
    },
    alteraDespesaPaga() {
      const clone = structuredClone(this.despesaSelecionada)
      const data = {
        id: clone.id,
        ano: this.anoAtual.toString(),
        mes: this.mesSelecionado,
        valorPago: clone.valorPago,
        dataPagto: clone.dataPagto,
        status: 'Pago',
        nota: clone.nota,
        data: clone.data,
        nome: clone.nome,
        valor: clone.valor
      }
      this.$store.dispatch('atualizaDespesa', data);
      this.despesaSelecionada = {};
      setTimeout(() => {
        this.updateFirebaseAnoAtualizado();
      }, 500);
    },
    alterarDespesa(despesa) {
      this.despesaSelecionada = structuredClone(despesa);
    },
    alteraDespesaState() {
      const clone = structuredClone(this.despesaSelecionada)
      const data = {
        id: clone.id,
        ano: this.anoAtual.toString(),
        mes: this.mesSelecionado,
        valorPago: clone.valorPago,
        dataPagto: clone.dataPagto,
        status: clone.status,
        nota: clone.nota,
        data: clone.data,
        nome: clone.nome,
        valor: clone.valor
      }
      this.$store.dispatch('atualizaDespesa', data);
      this.despesaSelecionada = {};
      setTimeout(() => {
        this.updateFirebaseAnoAtualizado();
      }, 500);
    },
    cancelaConfirmarPagto() {
      this.despesaSelecionada = {};
    },
    alteraAno(ano) {
      this.anoSelecionado = ano;
    },
    adcionaNovoAno() {
      var checkAnoExistente = this.dados.findIndex(i => i.ano === this.novoAno)
      if(checkAnoExistente < 0){
        const defaultNovoAno = {
          ano: this.novoAno,
          meses: [
            {
              mes: "Janeiro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Fevereiro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Março",
              entradas: [],
              despesas: []
            },
            {
              mes: "Abril",
              entradas: [],
              despesas: []
            },
            {
              mes: "Maio",
              entradas: [],
              despesas: []
            },
            {
              mes: "Junho",
              entradas: [],
              despesas: []
            },
            {
              mes: "Julho",
              entradas: [],
              despesas: []
            },
            {
              mes: "Agosto",
              entradas: [],
              despesas: []
            },
            {
              mes: "Setembro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Outubro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Novembro",
              entradas: [],
              despesas: []
            },
            {
              mes: "Dezembro",
              entradas: [],
              despesas: []
            }
          ],
        }
        this.$store.dispatch('addNovoAno', defaultNovoAno, this.userFirebase.uid)
        this.novoAno = "";
        this.flAddNovoAno = false;
      } else {
        alert("Ano já existente")
      }
    },
    excluirDespesa(despesa) {
      this.despesaSelecionada = structuredClone(despesa);
      var despesas = structuredClone(this.anoSelecionado);
      var arrDespesa = [];
      for(var i = 0; i < 12; i++) {
        let item = despesas.meses[i].despesas.find(d => d.id === despesa.id);
        if(typeof item === 'object') {
          this.$set(item, 'mes', i);
          this.$set(item, 'ano', this.anoAtual.toString());
          arrDespesa.push(item);
        }
      }
      this.despesasEncontradas = arrDespesa;
    },
    confirmaExclusao() {
      if (this.exclusaoSelecionada === 'unica') {
        const clone = structuredClone(this.despesaSelecionada);
        const data = {
          id: clone.id,
          ano: this.anoAtual.toString(),
          mes: this.mesSelecionado,
        }
        this.$store.dispatch('removeDespesa', data);
      } else if (this.exclusaoSelecionada === 'serie') {
        const clone = structuredClone(this.despesasEncontradas);
        this.$store.dispatch('removeDespesaRecorrente', clone);
      }
      setTimeout(() => {
        this.updateFirebaseAnoAtualizado();
        this.cancelaExclusao();
      }, 500);
    },
    cancelaExclusao() {
      this.despesaSelecionada = {};
      this.despesasEncontradas = [];
      this.exclusaoSelecionada = "";
    },
    editarEntrada(entrada) {
      this.entradaSelecionada = structuredClone(entrada);
    },
    confirmarAlteracaoEntrada() {
      const clone = structuredClone(this.entradaSelecionada);
      const data = {
        id: clone.id,
        valor: clone.valor,
        nota: clone.nota,
        nome: clone.nome,
        confirmado: clone.confirmado,
        data: clone.data,
        ano: this.anoAtual.toString(),
        mes: this.mesSelecionado
      }
      this.$store.dispatch('atualizaEntrada', data)
      setTimeout(() => {
        this.updateFirebaseAnoAtualizado();
        this.cancelaAlteracaoEntrada();
      }, 500);
    },
    confirmarExclucaoEntrada() {
      if (this.exclusaoSelecionada === 'unica') {
        const clone = structuredClone(this.entradaSelecionada);
        const data = {
          id: clone.id,
          ano: this.anoAtual.toString(),
          mes: this.mesSelecionado,
        }
        this.$store.dispatch('removeEntrada', data);
        setTimeout(() => {
          this.updateFirebaseAnoAtualizado();
          this.cancelaAlteracaoEntrada();
        }, 500);
      } else {
        this.exclusaoSelecionada = "";
        this.cancelaAlteracaoEntrada();
      }
    },
    cancelaAlteracaoEntrada() {
      this.entradaSelecionada = {};
    },
    verificaDados() {
      console.log('dados -> ', this.dados)
    }
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    }
  },
  mounted() {
    this.initValues();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.userFirebase.uid = user.uid;
        this.userFirebase.email = user.email;
        this.userFirebase.displayName = user.displayName;
      } else {
        this.$router.push({ name: 'Entrar' })
      }
    });
  }
}
</script>

<style>
.icone-novo-ano {
  color: rgb(129, 129, 129);
  font-size: 1.6rem;
  cursor: pointer;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
</style>
