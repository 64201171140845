<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-success">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <h5 class="mb-0 text-white">Controle Financeiro Pessoal</h5>
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/dashboard" class="nav-link text-white" active-class="active" tag="a" aria-current-value="page">Dashboard</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/operacao" class="nav-link text-white" active-class="active" tag="a" aria-current-value="page">Operação</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/relatorio" class="nav-link text-white" active-class="active" tag="a" aria-current-value="page">Relatório</router-link>
          </li>
        </ul>
        <!-- Menu Direito -->
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Menu</a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li><h6 class="dropdown-header">{{usuario.displayName}}</h6></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#">Configurações (em breve)</a></li>
              <li><a class="dropdown-item" href="#">Trocar senha</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#" @click="logoff">Logoff</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { auth } from '../../firebase/config';
import { signOut } from 'firebase/auth';

export default {
  name: "NavBar",
  data() {
    return {

    }
  },
  computed: {
    usuario() {
      return this.$store.state.usuario;
    }
  },
  methods: {
    logoff() {
      if (confirm("Deseja realmente sair?")) {
        sessionStorage.clear();
        signOut(auth).then(() => {
          this.$router.push({ name: 'Entrar' }).catch(() => {});
        })
      }
    },
  }
}
</script>

<style>

</style>
