<template>
  <div class="container-fluid pt-3">
    <div class="row pt-5">
      <div class="col-2">
        <h1>Ano: {{anoAtual}}</h1>
      </div>
      <template v-for="(anos, index) in dados">
      <div class="col-1 align-self-center" :key="index" v-if="anos.ano != anoAtual">
        <h6><a href="#" @click="alteraAno(anos.ano)">{{anos.ano}}</a></h6>
      </div>
      </template>
    </div>
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <perfect-scrollbar>
          <div class="d-flex flex-row">
            <div class="mx-1" v-for="(mes, index) in anoSelecionado.meses" :key="index">
              <div class="table-responsive">
                <table class="table table-sm" style="width:400px" :ref="`mes${index}`">
                  <thead>
                    <tr>
                      <th scope="col" colspan="3">{{mes.mes}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="table-danger" v-for="(despesa, index) in mes.despesas" :key="index">
                      <td class="fs-6" style="width:60%">{{despesa.nome}}</td>
                      <td class="fs-6" style="width:10%">
                        <i class="icone-desabilitado fas fa-check-circle" v-if="despesa.status == 'Pago'"></i>
                        <i class="text-warning fas fa-clock" v-else></i>
                      </td>
                      <td class="fs-6 fw-semibold">
                        <i class="text-danger far fa-arrow-alt-circle-up" v-if="despesa.valorPago > despesa.valor"></i>
                        <i class="text-success far fa-arrow-alt-circle-down" v-else-if="despesa.valorPago < despesa.valor"></i>
                        {{despesa.status == 'Pago' ? parseFloat(despesa.valorPago).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) : parseFloat(despesa.valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</td>
                    </tr>
                    <tr class="table-success" v-for="(entradas, index) in mes.entradas" :key="`e${index}`">
                      <td class="fs-6">{{entradas.nome}}</td>
                      <td>
                        <i class="icone-desabilitado fas fa-check-double" v-if="entradas.confirmado"></i>
                        <i class="text-warning fas fa-hourglass-half" v-else></i>
                      </td>
                      <td class="fs-6 fw-semibold">{{parseFloat(entradas.valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</td>
                    </tr>
                    <tr class="table-light">
                      <td class="fs-6 fw-bold">Total de despesas (previsto)</td>
                      <td></td>
                      <td class="fs-6 fw-bold"><p class="text-danger mb-0">{{calcTotalDespesas(mes.despesas).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</p></td>
                    </tr>
                    <tr class="table-light">
                      <td class="fs-6 fw-bold">Total de entradas</td>
                      <td></td>
                      <td class="fs-6 fw-bold"><span class="text-success">{{calcTotalEntradas(mes.entradas).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</span></td>
                    </tr>
                    <tr class="table-light">
                      <td class="fs-6 fw-bold">Restante Previsto</td>
                      <td></td>
                      <td class="fs-6 fw-bold"><span :class="calcRestantePrevisto(mes.despesas, mes.entradas) > -1 ? 'text-primary' : 'text-danger'">R$ {{calcRestantePrevisto(mes.despesas, mes.entradas).toLocaleString('pt-BR')}}</span></td>
                    </tr>
                    <tr class="table-light">
                      <td class="fs-6 fw-bold">Total de despesas (pagas)</td>
                      <td>
                        <i class="text-danger far fa-arrow-alt-circle-up" v-if="totalPago(mes.despesas) > calcTotalDespesas(mes.despesas)"></i>
                        <i class="text-success far fa-arrow-alt-circle-down" v-else-if="totalPago(mes.despesas) < calcTotalDespesas(mes.despesas)"></i>
                        <i class="text-primary fas fa-equals" v-else-if="totalPago(mes.despesas) == calcTotalDespesas(mes.despesas)"></i>
                      </td>
                      <td class="fs-6 fw-bold"><p class="text-danger mb-0">
                        {{totalPago(mes.despesas).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</p></td>
                    </tr>
                    <tr class="table-light">
                      <td class="fs-6 fw-bold">Restante Final</td>
                      <td>
                        <i class="text-success far fa-arrow-alt-circle-up" v-if="calcRestante(mes.despesas, mes.entradas) > calcRestantePrevisto(mes.despesas, mes.entradas)"></i>
                        <i class="text-warning far fa-arrow-alt-circle-down" v-else-if="calcRestante(mes.despesas, mes.entradas) < calcRestantePrevisto(mes.despesas, mes.entradas)"></i>
                        <i class="text-primary fas fa-equals" v-else-if="calcRestante(mes.despesas, mes.entradas) == calcRestantePrevisto(mes.despesas, mes.entradas)"></i>
                      </td>
                      <td class="fs-6 fw-bold"><span :class="calcRestante(mes.despesas, mes.entradas) > -1 ? 'text-success' : 'text-danger'">R$ {{calcRestante(mes.despesas, mes.entradas).toLocaleString('pt-BR')}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <!-- anoSelecionado -><pre>{{anoSelecionado}}</pre> -->
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
export default {
  name: "Relatorio",
  components: {
    PerfectScrollbar
  },
  data() {
    return {
      anoAtual: "",
      mesAtual: 0
    }
  },
  computed: {
    dados: {
      get() {
        return this.$store.state.anos;
      },
      set(newData) {
        this.$store.dispatch('defineNovoStateAnos', newData);
      }
    },
    anoSelecionado: {
      get() {
        //const dados = this.dados.find(i => i.ano === this.anoAtual.toString());
        const dados = this.$store.getters.getAnoSelecionado(this.anoAtual.toString());
        //console.log('dados no computed -> ', dados)
        return dados
      },
      set(newValue) {
        this.anoAtual = newValue
      },
    },
  },
  methods: {
    initValues() {
      const currentDate = new Date();
      this.anoAtual = currentDate.getFullYear();
      this.mesAtual = currentDate.getMonth();
    },
    carregarDados() {
      const json = localStorage.getItem('controle');
      this.dados = JSON.parse(json);
    },
    calcTotalDespesas(despesas) {
      if(despesas.length > 0) {
        var count = 0
        for(var d = 0; d < despesas.length; d++) {
          count += parseFloat(despesas[d].valor)
        }
        return count
      } else {
        return 0.00
      }
    },
    calcTotalEntradas(entradas) {
      if(entradas.length > 0) {
        var count = 0
        for(var d = 0; d < entradas.length; d++) {
          count += parseFloat(entradas[d].valor)
        }
        return count
      } else {
        return 0.00
      }
    },
    calcRestantePrevisto(despesas, entradas) {
      var despesasTotal = this.calcTotalDespesas(despesas);
      var entradasTotal = this.calcTotalEntradas(entradas);
      return entradasTotal - despesasTotal;
    },
    calcRestante(despesas, entradas) {
      var despesasTotal = this.totalPago(despesas);
      var entradasTotal = this.calcTotalEntradas(entradas);
      return entradasTotal - despesasTotal;
    },
    alteraAno(ano) {
      this.anoSelecionado = ano;
    },
    totalPago(despesas) {
      if(despesas.length > 0) {
        var count = 0
        for(var d = 0; d < despesas.length; d++) {
          count += parseFloat(despesas[d].valorPago)
        }
        return count
      } else {
        return 0.00
      }
    },
    goto(refName) {
      var element = this.$refs[refName];
      if (Array.isArray(element)){
        element[0].scrollIntoView({behavior: "auto", block: "end", inline: "center"});
      } else {
        element.scrollIntoView({behavior: "auto", block: "end", inline: "center"});
      }
    }
  },
  mounted() {
    this.initValues();
    setTimeout(() => {
      this.goto(`mes${this.mesAtual}`);
    }, 2000);
  }
}
</script>

<style>
.icone-desabilitado {
  font-size: 1.2rem;
  color: green;
}
</style>
